exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-index-js": () => import("./../../../src/pages/cennik/index.js" /* webpackChunkName: "component---src-pages-cennik-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leksykon-index-js": () => import("./../../../src/pages/leksykon/index.js" /* webpackChunkName: "component---src-pages-leksykon-index-js" */),
  "component---src-pages-oferta-biostymulator-tkankowy-profhilo-js": () => import("./../../../src/pages/oferta/biostymulator-tkankowy-profhilo.js" /* webpackChunkName: "component---src-pages-oferta-biostymulator-tkankowy-profhilo-js" */),
  "component---src-pages-oferta-electri-js": () => import("./../../../src/pages/oferta/electri.js" /* webpackChunkName: "component---src-pages-oferta-electri-js" */),
  "component---src-pages-oferta-gummy-smile-usmiech-dziaslowy-js": () => import("./../../../src/pages/oferta/gummy-smile-usmiech-dziaslowy.js" /* webpackChunkName: "component---src-pages-oferta-gummy-smile-usmiech-dziaslowy-js" */),
  "component---src-pages-oferta-jalupro-js": () => import("./../../../src/pages/oferta/jalupro.js" /* webpackChunkName: "component---src-pages-oferta-jalupro-js" */),
  "component---src-pages-oferta-karisma-js": () => import("./../../../src/pages/oferta/karisma.js" /* webpackChunkName: "component---src-pages-oferta-karisma-js" */),
  "component---src-pages-oferta-kwas-polimlekowy-sculptra-js": () => import("./../../../src/pages/oferta/kwas-polimlekowy-sculptra.js" /* webpackChunkName: "component---src-pages-oferta-kwas-polimlekowy-sculptra-js" */),
  "component---src-pages-oferta-leczenie-bruksizmu-js": () => import("./../../../src/pages/oferta/leczenie-bruksizmu.js" /* webpackChunkName: "component---src-pages-oferta-leczenie-bruksizmu-js" */),
  "component---src-pages-oferta-leczenie-lysienia-js": () => import("./../../../src/pages/oferta/leczenie-lysienia.js" /* webpackChunkName: "component---src-pages-oferta-leczenie-lysienia-js" */),
  "component---src-pages-oferta-leczenie-nadpotliwosci-bot-js": () => import("./../../../src/pages/oferta/leczenie-nadpotliwosci-bot.js" /* webpackChunkName: "component---src-pages-oferta-leczenie-nadpotliwosci-bot-js" */),
  "component---src-pages-oferta-mezoterapia-mikroiglowa-js": () => import("./../../../src/pages/oferta/mezoterapia-mikroiglowa.js" /* webpackChunkName: "component---src-pages-oferta-mezoterapia-mikroiglowa-js" */),
  "component---src-pages-oferta-mezoterapia-neauvia-hydro-deluxe-js": () => import("./../../../src/pages/oferta/mezoterapia-neauvia-hydro-deluxe.js" /* webpackChunkName: "component---src-pages-oferta-mezoterapia-neauvia-hydro-deluxe-js" */),
  "component---src-pages-oferta-modelowanie-powiekszanie-ust-js": () => import("./../../../src/pages/oferta/modelowanie-powiekszanie-ust.js" /* webpackChunkName: "component---src-pages-oferta-modelowanie-powiekszanie-ust-js" */),
  "component---src-pages-oferta-neauvia-explosion-js": () => import("./../../../src/pages/oferta/neauvia-explosion.js" /* webpackChunkName: "component---src-pages-oferta-neauvia-explosion-js" */),
  "component---src-pages-oferta-polinukleotydy-pluryal-js": () => import("./../../../src/pages/oferta/polinukleotydy-pluryal.js" /* webpackChunkName: "component---src-pages-oferta-polinukleotydy-pluryal-js" */),
  "component---src-pages-oferta-sunekos-js": () => import("./../../../src/pages/oferta/sunekos.js" /* webpackChunkName: "component---src-pages-oferta-sunekos-js" */),
  "component---src-pages-oferta-usuwanie-zmarszczek-mimicznych-bot-js": () => import("./../../../src/pages/oferta/usuwanie-zmarszczek-mimicznych-bot.js" /* webpackChunkName: "component---src-pages-oferta-usuwanie-zmarszczek-mimicznych-bot-js" */),
  "component---src-pages-oferta-wolumetria-twarzy-js": () => import("./../../../src/pages/oferta/wolumetria-twarzy.js" /* webpackChunkName: "component---src-pages-oferta-wolumetria-twarzy-js" */),
  "component---src-pages-oferta-wypelnianie-doliny-lez-js": () => import("./../../../src/pages/oferta/wypelnianie-doliny-lez.js" /* webpackChunkName: "component---src-pages-oferta-wypelnianie-doliny-lez-js" */),
  "component---src-pages-oferta-wypelnianie-zmarszczek-kwasem-hialuronowym-js": () => import("./../../../src/pages/oferta/wypelnianie-zmarszczek-kwasem-hialuronowym.js" /* webpackChunkName: "component---src-pages-oferta-wypelnianie-zmarszczek-kwasem-hialuronowym-js" */),
  "component---src-pages-zabiegi-medycyny-estetycznej-botoks-warszawa-index-js": () => import("./../../../src/pages/zabiegi-medycyny-estetycznej/botoks-warszawa/index.js" /* webpackChunkName: "component---src-pages-zabiegi-medycyny-estetycznej-botoks-warszawa-index-js" */),
  "component---src-pages-zabiegi-medycyny-estetycznej-index-js": () => import("./../../../src/pages/zabiegi-medycyny-estetycznej/index.js" /* webpackChunkName: "component---src-pages-zabiegi-medycyny-estetycznej-index-js" */),
  "component---src-pages-zabiegi-medycyny-estetycznej-kwas-hialuronowy-na-twarz-index-js": () => import("./../../../src/pages/zabiegi-medycyny-estetycznej/kwas-hialuronowy-na-twarz/index.js" /* webpackChunkName: "component---src-pages-zabiegi-medycyny-estetycznej-kwas-hialuronowy-na-twarz-index-js" */),
  "component---src-pages-zabiegi-medycyny-estetycznej-mezoterapia-index-js": () => import("./../../../src/pages/zabiegi-medycyny-estetycznej/mezoterapia/index.js" /* webpackChunkName: "component---src-pages-zabiegi-medycyny-estetycznej-mezoterapia-index-js" */),
  "component---src-pages-zabiegi-medycyny-estetycznej-stymulatory-tkankowe-index-js": () => import("./../../../src/pages/zabiegi-medycyny-estetycznej/stymulatory-tkankowe/index.js" /* webpackChunkName: "component---src-pages-zabiegi-medycyny-estetycznej-stymulatory-tkankowe-index-js" */),
  "component---src-templates-term-page-js": () => import("./../../../src/templates/termPage.js" /* webpackChunkName: "component---src-templates-term-page-js" */)
}

